<template>
    <loader v-bind="{ loading }">
        <div>
            <form>
                <columns>
                    <column>
                        <text-input required classes="is-medium is-rounded" :error="$root.errors.name"
                            v-model="area.name">Name</text-input>
                    </column>
                </columns>

                <columns>
                    <column>
                        <submit-button :working="updating" @submit="updateArea" class="is-rounded">
                            Save
                        </submit-button>
                    </column>
                </columns>
            </form>
        </div>
    </loader>
</template>
<script>
import { area as backend } from '@/api'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true,
        updating: false,
        area: {
            name: '',
        }
    }),

    beforeCreate() {
        backend.load(this.$route.params.area, ({data}) => {
            this.area = data
            this.loading = false
        }, () => this.loading = false)
    },

    methods: {
        updateArea() {
            this.updating = true
            backend.update(this.area, () => {
                this.$router.back()
                this.$toast.success('Saved')
            }, () => this.updating = false)
        }
    },

    computed: {
        ...mapGetters('location', [
            'location'
        ])
    },

}
</script>